.sidebarContainer {
  display: flex;
}
.sidebarSection {
  border: solid black 1px;
  padding: 150px 100px 0px 100px;
  width: 100%;
  background: black;
  color: #ffffff;
}
.logo img {
  width: 250px;
}

/* Menu Section Start */
.menuLinksSection {
  display: flex;
  justify-content: space-between;
  line-height: 2;
}
/* Menu Section End  */

/* Menu Other Options Start*/
.menuLinks {
  font-size: 18px;
  font-weight: 200;
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  align-items: start;
}
.menuLinks a {
  text-decoration: none;
  color: #ffffff;
  transition: all 0.8s;
}
.menuLinks a:hover {
  text-decoration: none;
  color: #ed3237;
}
.dropdown {
  position: relative;
  display: inline-block;
}
.dropdown a {
  display: flex;
  flex-direction: column;
  align-items: start;
}
.dropdown:hover .dropdownContent {
  display: block;
  width: 100%;
  padding-bottom: 5px;
}

.dropdownContent {
  display: none;
  position: absolute;
  background-color: black;
  min-width: 160px;
  color: #ed3237;
  box-shadow: 0px 8px 16px 0px rgba(255, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
}
.imageSection {
  z-index: 100;
}
.backLogo {
  position: absolute;
  background: #ffffff;
  border-radius: 50%;
  margin: 10px;
  padding: 10px;
  width: 55px;
  transition: ease-in-out 0.5s;
}
.backLogo:hover {
  border-radius: 20%;
  transition: ease-in-out 0.5s;
}
/* Menu Other Options End */

.social_icons {
    margin-top: 40px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border: 1px white solid;
  a {
    color: #ffffffc0;
    padding: 5px 10px;
  }
  a:hover {
    color: #ffffff;
  }
}


@media only screen and (max-width : 768px) {
    
  .sidebarContainer {
    flex-direction: column;
  }
  .sidebarSection {
    border: solid black 1px;
    padding: 30px;
    width: 100%;
    background: black;
    color: #ffffff;
  }
  .menuLinksSection {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    line-height: 2;
    gap: 25px;
  }
  .logo {
    text-align: center;
  }

  .backLogo {
    width: 35px;
  }
}