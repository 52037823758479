.manufacturingPage {
    background: #ffffff;
    margin: 0;
    padding-top: 120px;
}
.MenufactureIntro {
    text-align: center;
    padding: 50px 100px;
}
.FlatKnitsHeading h2 {
    font-size: 30px;
    font-weight: 200;
    font-family: "slapepro";
}

