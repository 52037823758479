

   
.sliderContainer
  {
  padding: 50px 0;
  
  /* overflow: hidden; */
  background: #ffffff;
  margin: 50px;
  text-align: center;
  display: block;
  align-items: center;
}


  .sliderContainer img {
    filter: grayscale(100%) opacity(0.7);
    width: 150px;
    height: auto;
    text-align: center;
    overflow: hidden;
    border: black solid 1px;
    border-radius: 50%;
  }
  .sliderContainer img:hover {
    filter: grayscale(0);
  }
  .Section {
    overflow: hidden;
    background: #ED3237;
  }

  @media only screen and (max-width: 768px) {
    .sliderContainer
  {
  padding: 20px 15px;
  margin: 10px;
  }
  .sliderContainer img {
    width: 50px;
    border-radius: 50%;
  }
  .sliderContainer img:hover {
    filter: grayscale(0);
  }

  }