.sectionsContainer {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    /* flex-direction: column; */
    padding: 100px 20px;
    gap: 20px;
    background: #ffffff;
}
/* .sectionImages img {
    padding: 20px;
    width: 250px;
    height: auto;
} */
.sectionImages {
    padding: 20px;
    text-align: center;
    background: #eaeaeacd;
    width: 400px;
    transition: ease-in-out all 0.4s;
}
.sectionImages:hover {
    transition: ease-in-out 0.8;
    box-shadow: 10px 15px 10px grey;
    background: #000;
    color: red;
   
    h4 {
        color: #ffffff;
    }

}



.certificationsPage {
    background: #ffffff;
    margin: 0;
    padding-top: 120px;
}
.certificationsIntro {
    text-align: center;
    padding: 50px 100px;
}
.certificationsHeading h2 {
    font-size: 30px;
    font-weight: 200;
    font-family: "slapepro";
}

@media only screen and (max-width : 768px) {
    
    .certificationsIntro {
        padding: 20px;
        p {
            text-align: justify;
        }
    }
    .sectionsContainer {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        padding: 20px;
        gap: 20px;
        background: #ffffff;
    }
}