.Footer {
    display: flex;
    justify-content: space-between;
    background: #000000;
    color: #d3d3d3;
    padding: 50px;
    gap: 50px;
}
.Footer h2 {
    color: #ffffff;
}
.fst img{
    width: 250px;
}
.fst,.fst2 {
    display: flex;
    flex-direction: column;
    width: 100%;
    row-gap: 10px;
    text-align: justify;
    align-items: center;
}
.fst2 h2 {
    margin-bottom: 30px;
}
.copywrite{
    color: #ffffff;
    background: #000;
    text-align: center;
    padding: 5px;
    margin: 0;
}
.socialIcons {
    display: flex;
    justify-content: space-between;
    padding: 5px;

    a {
        /* border-radius: 20%; */
        padding: 10px;
        margin: 10px;
        color: #ffffff;
    }
}
.shareMarket {
    color: #ed3237;
    font-size: 14px;

}
.shareMarket:hover {
    color: #ffffff;
}


@media only screen and (max-width : 768px){
    .Footer {
        flex-direction: column;
        justify-content: space-between;
        background: #000000;
        color: #d3d3d3;
        padding: 50px;
        gap: 50px;
    }
}