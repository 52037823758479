/* Header start */
.containerBanner {
    display: flex;
    overflow: hidden;
    height: 500px;
    img {
        width: 100%;
        height: 675px;
    }
}
.gradientbg {
    position: absolute;
    width: 100%;
    height: 500px;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.356), rgba(0, 0, 0, 0.308));
    z-index: 1;
}
.MenufactureIntro {
    text-align: center;
    padding: 50px ;
    height: 500px;
    color: #ffffff;
    display: flex;
    align-items: center;
    position: absolute;
    top: 70px;
    z-index: 1;
}
.FlatKnitsHeading h2 {
    font-size: 30px;
    font-weight: 200;
    font-family: "slapepro";
}
/* Heading End */

.toysHeading h2 {
    font-size: 30px;
    font-weight: 200;
    font-family: "slapepro";
}
.toysContainer1, .toysContainer2 {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 50px;
    background: #ffffff;
}

.toysContainer1 img, .toysContainer2 img {
    width: 100%;
    height: auto;
}

.ImagesSection {
    border: solid black 2px;
    padding: 30px;
    width: 674px;
}
.toysImages{
    border: solid black 2px;
    border-left: 1px solid black;
    padding: 30px;
    width: 675px;
}





/* Gallery Start */
.container {
    display: flex;
    flex-wrap: wrap;
    padding: 50px;
    align-items: center;
    background: #f7f0e8;
    text-align: center;
}

.imageSection img {
        width: 300px;
        padding: 20px;
    }

.imageSection {
    text-align: center;
}

/* Gallery End */


@media only screen and (max-width : 768px) {
    
    .MenufactureIntro {
        padding: 50px;
        top: 70px;
        text-align: center;
        p {
            text-align: justify;
        }
    }
    .ImagesSection, .JacketsImages {
        height: auto;
    }

    .containerBanner {
        height: 580px;
        img {
            width: 100%;
            object-fit: cover;
        }
    }
}