.sectionsContainer {
  display: flex;
  justify-content: center;
  margin: 10px;
  flex-wrap: wrap;
  padding: 100px 20px;
}
.sectionImages img {
  padding: 20px;
  /* width: 300px;
    height: auto; */
}
.sectionImages {
  padding: 20px;
  text-align: center;
  margin: 50px;
}

#video {
  position: fixed;
  z-index: -1;
  width: 100%;
  top: 0;
}


.sliderContainer {
  padding: 100px 0;

  /* overflow: hidden; */
  margin: 50px;
  text-align: center;
  display: flexbox;
  align-items: baseline;

  img {
    width: 300px;
  }
}

@media only screen and (max-width : 768px) {
  .sliderContainer {
    padding: 100px 20px 0px 20px;
    margin: 12px;
    img {
      width: 90px;
    }
  }
  #video {
    height: 300px;
  }
}
