.ctaBtn:hover {
    background-color: transparent;
    border: none;
    border-bottom: solid 1px rgb(245, 0, 0);
    color: #F7ED7E;
    text-shadow: 2px 0 10px black;
}
.ctaBtn {
    background-color: #ed3237 ;
    border-radius: 5px;
    color: #ffffff;
    transition: ease-out 0.5s;
    border:  none;
    text-shadow: 2px 10px 10px black;
    padding: 5px 30px;

    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
}

.ctaBtn {
    animation-name: example;
    animation-duration: 4s;
    animation-iteration-count: infinite;
  }


@media only screen and (max-width: 768px) {
  .ctaBtn {
    padding: 5px 10px; 
    font-size: 10px;
  }
 }