

.OurValuesHeading h2 {
    font-size: 30px;
    font-weight: 200;
    font-family: "slapepro";
}
.OurValuesIntro {
    text-align: center;
    padding: 50px 100px;
}
.OurValuesPage {
    background: #ffffff;
    margin: 0;
    padding-top: 120px;
}

.map {
    background: #f7f0e8;
img {
    width: 100%;
    padding: 50px 100px;
}
}

.container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 50px;
    align-items: center;
    text-align: center;
    background: #ffffff;
}
.imageSection img {
    width: auto;
    height: 312px;
    padding: 20px;
}

.imagesSection {
    display: flex;
}

.span {
    color: #ed3237;
}



@keyframes appear {
    from {
        opacity: 0;
        scale: 0;
        /* transform: translateX(-100px); */
        /* clip-path: inset(100% 100% 0 0); */
    }
    to {
        opacity: 1;
        scale: 1;
        /* transform: translateX(0px); */
        /* clip-path: inset(0 0 0 0); */
    }
}

/* .imageSection {
    animation: appear 10s linear;
    animation-timeline: view();
    animation-range: entry 0% cover 40%;
} */

@media only screen and (max-width : 768px) {
    .OurValuesIntro {
        text-align: center;
        padding: 25px;
        p {
            text-align: justify;
        }
        
    }
    .OurValuesPage {
    padding-top: 80px;
    }
    .map img {
        padding: 0px;
    }
    .container {
        padding: 20px;
    }

}