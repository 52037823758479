.manufacturingPage {
    background: #ffffff;
    margin: 0;
    padding-top: 120px;
}
.MenufactureIntro {
    text-align: center;
    padding: 50px 100px;
}
.FlatKnitsHeading h2 {
    font-size: 30px;
    font-weight: 200;
    font-family: "slapepro";
}
.span {
    color: #ed3237;
}


@media only screen and (max-width : 768px) {
    .MenufactureIntro {
        text-align: center;
        padding: 20px;
        p {
            text-align: justify;
        }
    }
    .MenufacturePage {
        padding-top: 50px;
    }
    .ourBusinessIntro {
        text-align: center;
        padding: 20px;
        p {
            text-align: justify;
        }
    }
    .manufacturingPage {
        padding-top: 100px;
    }
}