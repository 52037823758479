.header {
    background: #000;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 50px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
}
.logo img{
    width: 250px;
}
.mainMenu {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    /* border-bottom: 1px solid white; */
}
.mainMenu a {
    color: #fff;
    text-decoration: none;
    margin: 0 20px;
    transition: all 0.8s;
}
.mainMenu a:hover {
    color:#ed3237;
    border-radius: 5px;
}

.menuBtn {
    border: none;
    background: #ffffff;
    padding: 10px;
    border-radius: 50px;
      transition: ease-in-out 0.5s;
}


@media only screen and  (max-width : 768px) {
    .header {
       display: flex;
       flex-direction: column;
       padding: 10px;
       margin: 0;
       gap: 10px;
    }
    .logo img{ 
        width: 150px;
    }
    .mainMenu {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: baseline;
        color: white;
    }
    .mainMenu a {
        color: #fff;
        text-decoration: none;
        margin: 0 5px;
        transition: all 0.8s;
        font-size: 12px;
    }
    .mainMenu a:hover {
        color:#ed3237;
        border-radius: 5px;
    }
    
    .menuBtn {
        border: none;
        background: #ffffff;
        padding: 7px;
        border-radius: 50px;
          transition: ease-in-out 0.5s;
    }
    .menuBtn img {
        width: 20px;
    }
  }