.OurPeopleHeading h2 {
    font-size: 30px;
    font-weight: 200;
    font-family: "slapepro";
}
.OurPeopleIntro {
    text-align: center;
    padding: 50px 100px;
}
.OurPeoplePage {
    /* background: #ffffff; */
    background: #494949;
    margin: 0;
    padding-top: 120px;
    color: #ffffff;
}
.OurPeoplePage {
    background-image: url('/public/assets/BG/7.jpeg');
    background-position: center;
    background-size: cover;
  }
  
.imageSection img {
    width: 300px;
    padding: 20px;
}
.container {
    display: flex;
    flex-wrap: wrap;
    padding: 50px;
    align-items: center;
    text-align: center;
    justify-content: center;
    background: url(/public/assets/heroBg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    color: #ffffff;
   
}
.imageSection img {
    width: 312px;
    height: 312px;
}

.imagesSection {
    display: flex;
}











.OurPeopleContainer img {
    width: 500px;
    height: fit-content;
}
.imageBoxLeft {
    text-align: center;
    padding-left: 50px;
    text-align: center;
}
.containerRight {
    border-left: 1px solid black;
}

.imageBoxRight {
    text-align: center;
    padding-right: 50px;
}

 h2 {
    font-size: 24px;
    font-weight: 400;
    font-stretch: normal;
    text-decoration: none;
}


.imageBoxBottom {
    text-align: center;
    margin-top: 50px;
}
.hrTag {
    color: rgb(0, 0, 0);
    text-shadow: 50px 8px 16px 0px rgba(255, 0, 0, 0.2);
}
a {
    color: black;
    display: flexbox;
    align-items: center;
    text-decoration: none;
}
a:hover {
    color: #ED3237;
}

.span {
    color: #ED3237;
}


/* Gallery Start */




/* Gallery End */


/* 
.FlatKnitsHeading {
    margin-top: 120px;
    text-align: center;
    padding: 50px 100px;
} */



@keyframes appear {
    from {
        opacity: 0;
        scale: 0;
        /* transform: translateX(-100px); */
        /* clip-path: inset(100% 100% 0 0); */
    }
    to {
        opacity: 1;
        scale: 1;
        /* transform: translateX(0px); */
        /* clip-path: inset(0 0 0 0); */
    }
}

/* .imageSection {
    animation: appear 10s linear;
    animation-timeline: view();
    animation-range: entry 0% cover 40%;
} */


@media only screen and (max-width : 768px) {
    
    .OurPeopleIntro {
        padding: 30px 20px;
        p {
            text-align: justify;
        }
    }
}