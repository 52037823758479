.manufactureContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.manufactureContainer img {
  width: 500px;
  height: fit-content;
}
.imageBoxLeft {
  text-align: center;
  padding-left: 50px;
  text-align: center;
}
.containerRight {
  border-left: 1px solid black;
}

.imageBoxRight {
  text-align: center;
  padding-right: 50px;
}

h2 {
  font-size: 24px;
  font-weight: 400;
  font-stretch: normal;
  text-decoration: none;
}
.MenufactureIntro {
  text-align: center;
  padding: 170px 100px 100px 100px;
  height: 500px;
  color: #ffffff;
  display: flex;
  align-items: center;
}

.manufacturingPage {
  background-image: url("/public/assets/BG/7.jpeg");
  background-position: center;
  background-size: cover;
  color: #ffffff;
}

.imageBoxBottom {
  text-align: center;
  margin-top: 50px;
}
.hrTag {
  color: rgb(0, 0, 0);
  text-shadow: 50px 8px 16px 0px rgba(255, 0, 0, 0.2);
}

/* Gallery Start */
.container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  padding: 50px 100px;
  text-align: center;
}

.imageSection {
  gap: 20px;
  text-align: center;
  margin: 10px;
  img {
    width: 200px;
  }
}

#galleryImg1 {
  border: solid transparent 20px;
  border-image: linear-gradient(to right, #f0e8e2, #19191d);
  border-image-slice: 1;
}
#galleryImg2 {
  border: solid transparent 20px;
  border-image: linear-gradient(to right, #a11a1e, #1c1b20);
  border-image-slice: 1;
}
#galleryImg3 {
  border: solid transparent 20px;
  border-image: linear-gradient(to right, #d3ba7e, #494b73);
  border-image-slice: 1;
}
#galleryImg4 {
  border: solid transparent 20px;
  border-image: linear-gradient(to right, #f2e7e0, #877ca0);
  border-image-slice: 1;
}
#galleryImg5 {
  border: solid transparent 20px;
  border-image: linear-gradient(to right, #cebb9c, #394964);
  border-image-slice: 1;
}
#galleryImg6 {
  border: solid transparent 20px;
  border-image: linear-gradient(to right, #595b61, #6c3236);
  border-image-slice: 1;
}
#galleryImg7 {
  border: solid transparent 20px;
  border-image: linear-gradient(to right, #b78b8c, #8d8ea1);
  border-image-slice: 1;
}
#galleryImg8 {
  border: solid transparent 20px;
  border-image: linear-gradient(to right, #ab7d3b, #28111f);
  border-image-slice: 1;
}
/* Gallery End */

/* 
.FlatKnitsHeading {
    margin-top: 120px;
    text-align: center;
    padding: 50px 100px;
} */
.FlatKnitsHeading h2 {
  font-size: 30px;
  font-weight: 200;
  font-family: "slapepro";
}
h1 {
  color: #ed3237;
}

/* carousel */

.banner {
  width: 100%;
  height: 100vh;
  text-align: center;
  overflow: hidden;
  position: relative;
  background: #000;
  /* background: url(/public/assets/gallery-bg.jpg) no-repeat;
  background-size: cover; */
  /* background: linear-gradient(to bottom, black, rgb(226, 18, 53),pink,rgb(226, 18, 53), black); */
}

.banner .slider {
  background: url("/public/assets/logos/active-logo-gallery.png");
  background-repeat: no-repeat;
  background-position: bottom;
  position: absolute;
  width: 200px;
  height: 250px;
  top: 10%;
  left: calc(50% - 100px);
  transform-style: preserve-3d;
  transform: perspective(1000px);
  animation: autoRun 20s linear infinite;
}

@keyframes autoRun {
  from {
    transform: perspective(1000px) rotateX(-6deg) rotateY(0deg);
  }
  to {
    transform: perspective(1000px) rotateX(-6deg) rotateY(360deg);
  }
}

.banner .slider .item {
  position: absolute;
  inset: 0 0 0 0;
  transform: rotateY(
      calc((var(--position) - 1) * (360 / var(--quantity)) * 1deg)
    )
    translateZ(550px);
}

.banner .slider .item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}



@media only screen and (max-width: 768px) {
.MenufactureIntro {
    padding: 120px 50px 50px 50px;
    height: auto;
    display: flex;
    align-items: center;
   p {
    text-align: justify;
   }
}
.banner {
  width: 100%;
  height: 50vh;
  text-align: center;
  overflow: hidden;
  position: relative;
  background: #000;
}
.banner .slider .item img {
  width: 70%;
  height: 70%;
}

}
