.manufacturingPage {
    background: #ffffff;
    margin: 0;
    padding-top: 120px;
}
.MenufactureIntro {
    text-align: center;
    padding: 50px 100px;
}
.FlatKnitsHeading h2 {
    font-size: 30px;
    font-weight: 200;
    font-family: "slapepro";
}
/* .FlatKnitsHeading {
    margin-top: 120px;
    text-align: center;
    padding: 50px 100px;
} */

.valuesContainer {
    display: flex;
    padding: 20px;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    gap: 10px;
}
.imageSection img {
    width: 300px;
    padding: 20px;
}

.designingHeading {
    text-align: center;
    padding: 50px;
    background: #000000;
    color: #ffffff;
}

.designingHeading ul{
text-align: start;

}
ul {
    list-style: square;
  }
  li {
    padding: 10px;
  }
  

  @media only screen and (max-width : 768px) {
    
    .MenufactureIntro {
        padding: 20px;
        p {
            text-align: justify;
        }
    }
    .manufacturingPage {
        padding-top: 100px;
    }
    .valuesContainer {
        flex-wrap: wrap;
    }
}