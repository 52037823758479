.OurValuesHeading h2 {
  font-size: 30px;
  font-weight: 200;
  font-family: "slapepro";
}
.OurValuesIntro {
  text-align: center;
  padding: 50px 100px;
}
.OurValuesPage {
  background: #ffffff;
  margin: 0;
  padding-top: 120px;
  color: #ffffff;
}
.OurValuesPage {
  /* background-image: url('/public/assets/bgBanner.jpg'); */
  background-image: url("/public/assets/BG/7.jpeg");
  background-position: center;
  background-size: cover;
}
.imageSection img {
  width: 300px;
  padding: 20px;
}
.container {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  align-items: center;
  text-align: center;
}
.valuesContainer {
  display: flex;
  padding: 20px;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  gap: 10px;
  background: #ffffff;
}
.imageSection {
  border: solid black 2px;
  padding: 20px;
}
.imageSection:hover {
  background: #ed3237;
  color: #ffffff;
  transition: all 0.8s;
  box-shadow: 10px 20px 15px black;
  border: none;
}
#IdSection {
  background: #ed3237;
  color: #ffffff;
  transition: all 0.8s;
}
#IdSection:hover {
  background: #ffffff;
  color: #000000;
  transition: all 0.8s;
  box-shadow: 10px 20px 15px black;
  border: none;
}

@media only screen and (max-width: 768px) {
  .OurValuesIntro {
    padding: 30px 20px;
    p {
      text-align: justify;
    }
  }
  .OurValuesPage {
    padding-top: 100px;
  }
  .valuesContainer {
    flex-direction: column;
  }

  #IdSection:hover {
    background: #ed3237;
    color: #ffffff;
    transition: all 0.8s;
    box-shadow: 10px 20px 15px black;
    border: none;
  }
  #IdSection {
    background: #ffffff;
    color: #000000;
    transition: all 0.8s;
  }
}
