.mainContainer {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: -1;
    background: #000;
}
.containerBanner {
    display: flex;
    overflow: hidden;
    height: 675px;
}
/* .containerBanner {
    background-image: url('/public/assets/bg.png');
    background-repeat: no-repeat;
    background-size: cover;
} */
/* .gradientbg {
    width: 100%;
    height: 675px;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.493), rgba(0, 0, 0, 0.233));
    z-index: 0.5;
} */
.bannerHeadings {
    position: absolute;
    top: 50%;
    color: rgb(233, 233, 233);
    margin: 20%;
    padding: 20px;
    text-align: center;
    background: #00000095;
    visibility: hidden;
}
.heroHeading {
    font-size: 30px;
    font-weight: 400;
    text-transform: uppercase;
    font-family: "slatepro";
    font-stretch: normal;
}
.heroSubHeading {
    font-size: 24px;
    font-weight: 200;
    text-transform: uppercase;
    font-family: "slatepro";
    font-stretch: normal;
}
.ctaBtn {
    /* background-color: transparent; */
    background-color: #ed3237;
    color: white;
    padding: 5px 30px;
    border: none;
    border-radius: 20px;
    margin: 10px;
    z-index: 1;
}
.ctaBtn:hover {
    background-color: transparent;
    border: solid 1px white;
    transition: ease-in-out 0.5s;
}
.bannerBottomContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 440px;
    background-image: linear-gradient(to bottom, transparent, black);
    width: 100%;
    text-align: center;
    color: white;
}
.bannerBottomsections img {
    background-color: #ffffff;
    padding: 10px;
    border-radius: 50%;
    z-index: 10;
}

.bannerBottomsections h2, .bannerBottomsections a {
    font-size: 24px;
    font-weight: 200;
    font-stretch: normal;
    text-decoration: none;
    color: #ffffff;
    text-transform: capitalize;
}
.bannerBottomsections {
    border-right: 0.5px solid #ffffff;
    padding: 0 50px;
    overflow: hidden;
    text-align: center;
    margin-top: 70px;
}

#video {
    position: fixed;
    z-index: -1;
    width: 100%;
    height: 620px;
    width: 100%;
    background: #000;

}

@media only screen and (max-width: 480px) {
    #video {
        z-index: -1;
        width: 100%;
        height: 270px;
        margin: 50px 0px;
    }
    
}