.manufacturingPage {
    background: #ffffff;
    margin: 0;
    padding-top: 120px;
    color: #ffffff;
}
.manufacturingPage {
    /* background-image: url('/public/assets/bgBanner.jpg'); */
    background-image: url('/public/assets/BG/7.jpeg');
    background-position: center;
    background-size: cover;
  }
  
.MenufactureIntro {
    text-align: center;
    padding: 50px 100px;
}
.FlatKnitsHeading h2 {
    font-size: 30px;
    font-weight: 200;
    font-family: "slapepro";
}


#video {
    width: 85%;
    /* height: fit-content; */
    text-align: center;
    object-fit: cover;
}
.Video_Container {
    text-align: center;
    padding: 20px;
    margin: 50px 0px;
    background: #fcfcfc;
}


@media only screen and (max-width : 768px) {
    
    .MenufactureIntro {
        padding: 20px;
        top: 60px;
        text-align: center;
        p {
            text-align: justify;
        }
    }
    .Video_Container {
        text-align: center;
        padding: 10px;
        margin: 0px 0px;
        background: #fcfcfc;
    }
    #video {
        width: 100%;
        text-align: center;
        object-fit: cover;
    }
    .manufacturingPage {
        padding-top: 100px;
    }

}