/* Heading Start */
.containerBanner {
    display: flex;
    overflow: hidden;
    height: 375px;
    img {
        width: 100%;
        height: 675px;
    }
}
.gradientbg {
    position: absolute;
    width: 100%;
    height: 375px;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.596), rgba(0, 0, 0, 0.754));
    z-index: 1;
}

/*  */
/* .MenufactureIntro {
    text-align: center;
    padding: 100px ;
    width: 100%;
    height: auto;
    color: #ffffff;
    display: flex;
    align-items: center;
    position: absolute;
    top: 100px;
    z-index: 1;
} */
/*  */

.MenufactureIntro {
    /* text-align: center;
    padding: 100px ;
    height: 500px;
    color: #ffffff;
    display: flex;
    align-items: center;
    position: absolute;
    top: 50px;
    z-index: 1; */

    position: absolute;
    z-index: 1;
    top: 20%;
    left: 40%;
    color: White;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.FlatKnitsHeading h2 {
    font-size: 30px;
    font-weight: 200;
    font-family: "slapepro";
}
/* Heading End */


/* .manufacturingPage {
    text-align: center;
    border: 2px solid greenyellow;
} */
.MenufactureIntro {
    text-align: center;
    padding: 50px;
}

.span {
    color: #ed3237;
}



.Container {
    display: flex;
    justify-content: start;
    align-items: start;
    padding: 20px;
    flex-wrap: wrap;
    gap: 20px;
    background: #ffffff;

}
.Boxes {
    border: 1px solid black;
    width: 300px;
    padding: 50px;
}


@media only screen and (max-width : 768px) {
    
    .MenufactureIntro {
        padding: 20px;
        top: 80px;
        text-align: center;
        p {
            text-align: justify;
        }
    }
    .MenufactureIntro {
        top: 15%;
        left: 25%;
    }
    .containerBanner {
        display: flex;
        overflow: hidden;
        height: 275px;
        img {
            width: 100%;
            height: 275px;
            object-fit: contain;
        }
    }
    .gradientbg {
        position: absolute;
        width: 100%;
        height: 275px;
        background-image: linear-gradient(to right, rgba(0, 0, 0, 0.596), rgba(0, 0, 0, 0.754));
        z-index: 1;
    }
}