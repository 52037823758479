.ourBusinessPage {
    background: #ffffff;
    margin: 0;
    padding-top: 120px;
}
.ourBusinessIntro {
    text-align: center;
    padding: 50px 100px;
}
.ourBusinessHeading h2 {
    font-size: 30px;
    font-weight: 200;
    font-family: "slapepro";
}

.businessImg img {
    width: 100%;
    height: auto;
}
.span {
    color: #ed3237;
    font-size: 20px;
    font-weight: 600;
}

@media only screen and (max-width : 768px) {
    .ourBusinessIntro {
        text-align: center;
        padding: 20px;
        p {
            text-align: justify;
        }
    }
    .ourBusinessPage {
        padding-top: 80px;
    }
}