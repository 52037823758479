
/* 
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100..900&family=Playpen+Sans:wght@100..800&family=Playwrite+GB+S:ital,wght@0,100..400;1,100..400&display=swap'); */



/* @import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&family=Noto+Sans+JP:wght@100..900&family=Playpen+Sans:wght@100..800&family=Playwrite+GB+S:ital,wght@0,100..400;1,100..400&display=swap'); */




@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&family=Noto+Sans+JP:wght@100..900&family=Orbitron:wght@400..900&family=Playpen+Sans:wght@100..800&family=Playwrite+GB+S:ital,wght@0,100..400;1,100..400&display=swap');

 h2 {
    font-size: 24px;
    font-weight: 400;
    font-stretch: normal;
    text-decoration: none;
}
.MenufactureIntro {
    text-align: center;
    padding: 170px 100px 100px 100px;
    height: 500px;
    color: #ffffff;
    display: flex;
    align-items: center;
    /* position: absolute;
    top: 70px; */
}

.FlatKnitsHeading h2 {
    font-size: 30px;
    font-weight: 200;
    font-family: "slapepro";
}
h1 {
    color: #ed3237;
    text-transform: capitalize;
   
  /* font-family: "Dancing Script", cursive;
  font-optical-sizing: auto;
  font-weight: 100;
  font-style: normal; */


  font-family: "Orbitron", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;

  

}






/* Gallery Start */


/* Gallery End */



.sectionsContainer {
    display: flex;
    justify-content: center;
    background: #494949;
}
.sectionImages img {
    padding-bottom: 20px;
}
.sectionImages {
    padding: 20px;
    text-align: center;
    margin: 10px;
}
.sectionImages:hover {
    box-shadow: 10px 15px 10px grey;

}
#sectionText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #ffffff;
}
#sectionText:hover {
    box-shadow: none;
}





/*  */
.manufacturingPage {
    background-image: url('/public/assets/BG/7.jpeg');
    background-position: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.containerBanner {
    display: flex;
    overflow: hidden;
    height: 475px;
}
/* .containerBanner {
    background-image: url('31.mp4');
    background-image: url('/public/assets/Activ.png');
    background-repeat: no-repeat;
    background-size: cover;
} */
.gradientbg {
    width: 100%;
    height: 675px;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.586), rgba(0, 0, 0, 0.754));
    z-index: 0.5;
}
/*  */



/*  */

#sectionImages1 {
    background-color: rgba(172, 255, 47, 0.668);
    padding: 100px;
    width: 600px;
    height: auto;
    h2{
        padding: 0px;
    }
}
#sectionImages1:hover {
    background-image: url(/public/assets/29.jpg);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    color: white;
    overflow: hidden;
    p{
        visibility: hidden;
    }
    h2{
        backdrop-filter: blur(10px);
        font-weight: 600;
        color: #ffffff;
    }
    

}
#sectionImages2 {
    background-color: rgba(245, 197, 74, 0.668);
    padding: 100px;
    width: 600px;
    height: auto;
}
#sectionImages2:hover {
    background-image: url(/public/assets/37.jpg);
    background-size: cover;
    color: white;
    p{
        visibility: hidden;
    }
    h2{
        backdrop-filter: blur(10px);
        font-weight: 600;
        color: white;
    }
    
}
#sectionImages3 {
    background-color: rgba(47, 255, 238, 0.668);
    padding: 100px;
    width: 700px;
    height: auto;
}
#sectionImages3:hover {
    background-image: url(/public/assets/Jackets/90.jpg);
    background-position: bottom;
    background-size: cover;
    color: white;
    p{
        visibility: hidden;
    }
    h2{
        backdrop-filter: blur(10px);
        font-weight: 600;
        color: white;
    }
    
}
#sectionImages4 {
    background-color: rgba(224, 138, 255, 0.668);
    padding: 100px;
    width: 600px;
    height: auto;
}
#sectionImages4:hover {
    background-image: url(/public/assets/shoeuppers.jpg);
    background-size: cover;
    color: white;
    p{
        visibility: hidden;
    }
    h2{
        backdrop-filter: blur(10px);
        font-weight: 600;
        color: white;
    }
    
}
#sectionImages5 {
    background-color: rgba(255, 47, 151, 0.668);
    padding: 100px;
    width: 600px;
    height: auto;
}
#sectionImages5:hover {
    background-image: url(/public/assets/CircularKnits/97.jpg);
    background-size: cover;
    color: white;
    transition: ease-in-out 0.8s;
    p{
        visibility: hidden;
    }
    h2{
        backdrop-filter: blur(10px);
        font-weight: 600;
        color: white;
    }
    
}
/*  */

#video {
    position: absolute;
    z-index: -1;
    width: 100%;
}


@keyframes appear {
    from {
        opacity: 0;
        scale: 0;
        /* transform: translateX(-100px); */
        /* clip-path: inset(100% 100% 0 0); */
    }
    to {
        opacity: 1;
        scale: 1;
        /* transform: translateX(0px); */
        /* clip-path: inset(0 0 0 0); */
    }
}

/* .sectionImages {
    animation: appear 10s linear;
    animation-timeline: view();
    animation-range: entry 0% cover 40%;
} */

span {
    color: #fafafa;
    font-size: 20px;
    font-weight: 700;
}


@media only screen and (max-width: 768px) { 
    .MenufactureIntro {
        padding: 120px 50px 50px 50px;
        height: auto;
        display: flex;
        align-items: center;
       p {
        text-align: justify;
       }
    }


    .sectionsContainer {
        flex-direction: column;
    }
    .sectionImages {
        padding: 20px;
        text-align: center;
        margin: 0px;
    }


    #sectionImages1 {
        padding: 50px;
        width: auto;
    }
    #sectionImages2 {
        padding: 50px;
        width: auto;
    }
    #sectionImages3 {
        padding: 50px;
        width: auto;
    }
    #sectionImages4 {
        padding: 50px;
        width: auto;
    }
    
    #sectionImages5 {
        padding: 50px;
        width: auto;
    }
}