.aboutUsPage {
    background: #ffffff;
    margin: 0;
    padding-top: 150px;
}

.aboutUsIntro {
    text-align: center;
    padding: 150px 50px 50px 50px;
    overflow: hidden;
    background: #ffffff;
}
.FlatKnitsHeading h2 {
    font-size: 30px;
    font-weight: 200;
    font-family: "slapepro";
}
.FlatKnitsHeading p{
    padding: 10px;
    text-align: justify;
}

.container {
    display: flex;
    justify-content: space-between;
    padding: 100px;
    text-align: center;
    /* background: #000; */
    color: #ffffff;
    background-image: url('/public/assets/BG/5.jpeg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.innerBoxes h2 {
    color: #ffffff;
    font-size: 50px;
}
.VissionContainer {
    display: flex;
    gap: 20px;
    padding: 50px;
    background: #ffffff;
}
.Vissionsections {
    width: 600px;
    height: auto;
    padding: 50px;
    background: #ed3238;
    color: #ffffff;

}
.activeBanner {
    text-align: center;
}
.activeBanner img {
    width: 100%;
    height: fit-content;
}


.awardsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 100px;
    column-gap: 50px;
    text-align: justify;
    background: #f7f0e8;
}
.sections h2{
    text-align: center;
}
.sections img {
    width: 100%;
}

.span {
    color: #ed3237;
    font-size: 20px;
    font-weight: 600;
}

@media only screen and (max-width : 768px) {
    
    .container {
        flex-wrap: wrap;
        flex-direction: column;
        padding: 10px;
        gap: 20px;
    }
    .innerBoxes h2 {
        font-size: 30px;
    }
    .Vissionsections {
        width: auto;
    }
    .VissionContainer {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 10px;
    }
    .awardsContainer {
        padding: 50px;
        column-gap: 10px;
    }
    .aboutUsIntro {
        text-align: center;
        padding: 100px 20px 20px 20px;
        overflow: hidden;
        background: #ffffff;
    }
}