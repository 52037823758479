.manufactureContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.manufactureContainer img {
    width: 500px;
    height: fit-content;
}
.imageBoxLeft {
    text-align: center;
    padding-left: 50px;
    text-align: center;
}
.containerRight {
    border-left: 1px solid black;
}

.imageBoxRight {
    text-align: center;
    padding-right: 50px;
}

 h2 {
    font-size: 24px;
    font-weight: 400;
    font-stretch: normal;
    text-decoration: none;
}
.MenufactureIntro {
    text-align: center;
    padding: 50px 100px;
}
.manufacturingPage {
    background: #ffffff;
    margin: 0;
    padding-top: 120px;
    
    color: #ffffff;
}
.manufacturingPage {
    /* background-image: url('/public/assets/bgBanner.jpg'); */
    background-image: url('/public/assets/BG/7.jpeg');
    background-position: center;
    background-size: cover;
  }
  

.imageBoxBottom {
    text-align: center;
    margin-top: 50px;
}
.hrTag {
    color: rgb(0, 0, 0);
    text-shadow: 50px 8px 16px 0px rgba(255, 0, 0, 0.2);
}
a {
    color: black;
    display: flexbox;
    align-items: center;
    text-decoration: none;
}
a:hover {
    color: #ED3237;
}




/* Gallery Start */
.container {
    display: flex;
    flex-wrap: wrap;
    padding: 50px;
    align-items: center;
    text-align: center;
}

.imageSection img {
        width: 300px;
        padding: 20px;
    }

/* Gallery End */


/* 
.FlatKnitsHeading {
    margin-top: 120px;
    text-align: center;
    padding: 50px 100px;
} */
.FlatKnitsHeading h2 {
    font-size: 30px;
    font-weight: 200;
    font-family: "slapepro";
}


.tuckImage {
    width: 100px;
    height: 100px;
    position: absolute;
    animation-name: example;
    animation-duration: 6s;
    animation-iteration-count: infinite;
    /* opacity: 0.2; */
  }

@keyframes example {
    0%   { left: 100%; top:70%;}
    100% { left: -200px; top:70%;};
  }


  @media only screen and (max-width: 768px) {
    .MenufactureIntro {
      padding: 10px 50px;
      height: auto;
      display: flex;
      align-items: center;
      p {
        text-align: justify;
      }
    }
  
    .MenufactureIntro {
      text-align: center;
      padding: 10px 30px;
    }
  }