/* Heading Start */
.containerBanner {
    display: flex;
    overflow: hidden;
    height: 500px;
    img {
        width: 100%;
        height: 675px;
    }
}
.gradientbg {
    position: absolute;
    width: 100%;
    height: 500px;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.356), rgba(0, 0, 0, 0.308));
    z-index: 1;
}
.MenufactureIntro {
    text-align: center;
    padding:  0 20% ;
    height: 500px;
    color: #ffffff;
    display: flex;
    align-items: center;
    position: absolute;
    top: 70px;
    z-index: 1;
}
.FlatKnitsHeading h2 {
    font-size: 30px;
    font-weight: 200;
    font-family: "slapepro";
}
/* Heading End */


.JacketsContainer1, .JacketsContainer2 {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    margin-top: 50px;
    background-color: #ffffff;
}

.JacketsContainer1 img, .JacketsContainer2 img {
    width: 100%;
    height: auto;
}

.ImagesSection {
    border: solid black 2px;
    border: 1px solid black;
    padding: 30px;
    width: 674px;
    height: 220px;
}

.JacketsImages{
    border: solid black 2px;
    border-left: 1px solid black;
    padding: 30px;
    width: 675px;
    height: 220px;
}

.span {
    color: #ed3237;
}



@media only screen and (max-width : 768px) {
    
    .MenufactureIntro {
        padding: 50px;
        top: 50px;
        text-align: center;
        p {
            text-align: justify;
        }
    }
    .ImagesSection, .JacketsImages {
        height: auto;
    }
}