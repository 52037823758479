.manufacturingPage {
    background: #ffffff;
    margin: 0;
    padding-top: 120px;
}
.MenufactureIntro {
    text-align: center;
    padding: 50px 100px;
}
.FlatKnitsHeading h2 {
    font-size: 30px;
    font-weight: 200;
    font-family: "slapepro";
}



.valuesContainer {
    display: flex;
    padding: 50px;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    gap: 10px;
    background: #ffffff;
}
.imageSection img {
    width: 300px;
    padding: 20px;
}
.imageSection{
    padding: 20px;
    background: #ffffff;
 }
 
 .span {
    color: #ed3237;
 }
 .btns_color {
    background: #ED3237;
    color: #ffffff;
    border: none;
    padding: 7px 15px;
    border-radius: 20px;
    transition: ease all 0.5s;
}
.btns_color:hover {
    background: #F7ED7E;
    color: #000000;
    border: none;
    padding: 7px 15px;
    border-radius: 20px;
    border: solid black 1px;
}

@media only screen and (max-width : 768px) {
    .MenufactureIntro {
        text-align: center;
        padding: 20px;
        p {
            text-align: justify;
        }
    }
    .MenufacturePage {
        padding-top: 50px;
    }
    .ourBusinessIntro {
        text-align: center;
        padding: 20px;
        p {
            text-align: justify;
        }
    }
    .manufacturingPage {
        padding-top: 100px;
    }
    .valuesContainer {
        flex-wrap: wrap;
        padding: 20px;
    }
}