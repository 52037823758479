h2 {
    font-size: 24px;
    font-weight: 400;
    font-stretch: normal;   
    text-decoration: none;
    z-index: 100;
}

/* Contact */


/* Heading Start */
.containerBanner {
    display: flex;
    overflow: hidden;
    height: 575px;
    img {
        width: 100%;
        height: 675px;
    }
}
.gradientbg {
    position: absolute;
    width: 100%;
    height: 575px;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.596), rgba(0, 0, 0, 0.754));
    z-index: 1;
}
.MenufactureIntro {
    text-align: center;
    padding: 100px ;
    width: 100%;
    height: auto;
    color: #ffffff;
    display: flex;
    align-items: center;
    position: absolute;
    top: 70px;
    z-index: 1;
}
.FlatKnitsHeading h2 {
    font-size: 30px;
    font-weight: 200;
    font-family: "slapepro";
}
/* Heading End */



.CorporateOffice {
    text-align: center;
    padding: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
}
.FormSection {
    width: 100%;
    text-align: start;
    padding: 20px 50px;
    gap: 20px;
    
    img {
        width: 100%;
    }
    h4 {
        font-size: 16px;
        /* text-decoration: underline; */
        color: red;
    }
}


/* Contact Us Start*/
.formContainer {
    width: 100%;
    text-align: center;
    background: url('/public/assets/warehouse-clothing-textiles.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    form {
        background: #000000be;
        padding: 50px 20px;
        color: #ffffff;
    }
        input {
            width: 60%;
            margin: 10px 20px;
            padding: 10px;
            border-radius: 10px;
            border: 1px solid black;
        }
        .formBtn {
            display: flex;
            text-align: center;
            justify-content: space-evenly;

        }
}
/* Contact US End */


@media only screen and (max-width : 768px) {
    
    .MenufactureIntro {
        padding: 20px;
        top: 50px;
        text-align: center;
        p {
            text-align: justify;
        }
    }
    .MenufactureIntro {
        height: 500px;
        top: 50px;
    }
    .CorporateOffice {
        flex-direction: column;
    }
}