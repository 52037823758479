.homeContainer {
    width: 100%;
    background: #000000ab;
    /* margin: 1000px 0 0 0 ; */
    position: absolute;
    top: 700px;
    z-index: 100;
}


.row {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.columns {
    border: solid 0.2px rgb(255, 255, 255);
    padding: 56px 80px;
    color: #ffffff;
}
.columns h2 {
    text-transform: uppercase;
    z-index: 1001;
}
.manufacturingCol {
    background-image: url('/public/assets/BG/1.jpeg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.distributionCol {
    background-image: url('/public/assets/BG/3.jpeg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.retailCol {
    background-image: url('/public/assets/BG/2.jpeg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.kiaandcoCol {
    background-image: url('/public/assets/BG/4.jpeg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}



@media only screen and (max-width: 768px) { 
    .homeContainer {
        top: 320px;
    }
    .row {
        display: inline-block;
    }
    .columns {
        border: solid 0.2px rgb(255, 255, 255);
        padding: 25px 10px;
        color: #ffffff;
        height: 25%;
    }
    .columns h2 {
        font-size: 16px;
    }
    .columns p {
        font-size: 12px;
    }
    
}